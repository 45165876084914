import { Injectable } from '@angular/core';
import { S3Client, GetObjectCommand, ListObjectsCommand, PutObjectCommand, GetObjectCommandOutput } from '@aws-sdk/client-s3';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { Attachment } from './models/attachments.model';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

@Injectable({
  providedIn: 'root'
})

export class S3Service {
  private client: S3Client;

  constructor(
    private authService: AuthService,
  ) {
    this.client = new S3Client({
      region: environment.s3Bucket.region,
      endpoint: environment.s3Bucket.endpoint,
      forcePathStyle: environment.s3Bucket.forcePathStyle,
      credentials: () => this.authService.getUserCredentials()
    });
  }

  public async saveUserAvatar(email: string, file: File): Promise<string> {
    const key = `${email}/avatar`;
    await this.client.send(new PutObjectCommand({
      Bucket: environment.s3Bucket.userAvatar,
      Key: key,
      Body: file,
      ContentType: file.type
    }));
    return `https://${environment.s3Bucket.userAvatar}.s3.${environment.s3Bucket.region}.amazonaws.com/${key}`;
  }

  public async fetchUserAvatar(key: string): Promise<GetObjectCommandOutput> {
    const getCommand = new GetObjectCommand({
      Bucket: environment.s3Bucket.userAvatar,
      Key: key
    });
    return await this.client.send(getCommand);
  }

  public async saveTicketAttachment(email: string, id: string, file: File): Promise<Attachment> {
    const prefix = `${email}/${id}`;
    const key = `${prefix}/${file.name}`;
    await this.client.send(new PutObjectCommand({
      Bucket: environment.s3Bucket.tickets,
      Key: key,
      Body: file,
      ContentType: file.type
    }));

    return new Attachment({
      bucketName: environment.s3Bucket.tickets,
      size: file.size,
      prefix: prefix,
      key,
    });
  }

  public async getAttachments(email: string, id: string): Promise<Attachment[]> {
    const listTicketCommand = new ListObjectsCommand({
      Bucket: environment.s3Bucket.tickets,
      Prefix: `${email}/${id}`
    });
    const ticketAttachments = await this.client.send(listTicketCommand);
    if (!ticketAttachments.Contents) {
      return [];
    }
    return ticketAttachments.Contents.map(attachment => {
      return new Attachment({
        size: attachment.Size,
        key: attachment.Key,
        prefix: ticketAttachments.Prefix,
        bucketName: ticketAttachments.Name,
      });
    }).filter(Boolean) as Attachment[];
  }

  public async getAttachmentURL(key: string, bucketName: string): Promise<string> {
    const command = new GetObjectCommand({
      Bucket: bucketName,
      Key: key
    });
    return getSignedUrl(this.client, command, { expiresIn: 3600 });
  }

  public async getRecords(companyId: string, assignedPlanId: string, exerciseId: string, email: string): Promise<Record[] | null> {
    const filesCount = await this.getRecordCount(companyId, assignedPlanId, exerciseId, email);
    const recordsPromises: Promise<Record[]>[] = [];

    for (let i = 0; i < filesCount; i++) {
      const getRecordsCommand = new GetObjectCommand({
        Bucket: environment.s3Bucket.records,
        Key: `${companyId}/${assignedPlanId}/${exerciseId}/${email}/${i + 1}.json`
      });
      recordsPromises.push(this.fetchRecord(getRecordsCommand));
    }
    const recordsArray = await Promise.all(recordsPromises);
    const records: Record[] = recordsArray.reduce((toArray, fromArray) => toArray.concat(fromArray), []);
    records.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());
    return records;
  }

  private async fetchRecord(command: GetObjectCommand): Promise<Record[]> {
    const record = await this.client.send(command);
    const body = await new Response(record.Body as Blob, {}).text();
    return JSON.parse(body) as Record[];
  }

  private async getRecordCount(companyId: string, assignedPlanId: string, exerciseId: string, email: string): Promise<number> {
    const listRecordsCommand = new ListObjectsCommand({
      Bucket: environment.s3Bucket.records,
      Prefix: `${companyId}/${assignedPlanId}/${exerciseId}/${email}`,
    });
    const records = await this.client.send(listRecordsCommand);
    return records.Contents!.length;
  }
}

export interface Record {
  time: string,
  code_editor?: CodeEditorRecord,
  terminal?: TerminalRecord
}

export interface CodeEditorRecord {
  output: string,
  file_name: string,
  is_diff: boolean,
}

export interface TerminalRecord {
  output: string,
  columns: number,
  rows: number,
}