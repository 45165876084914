import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Subscription, interval } from 'rxjs';
import { Status } from '../../../../exercise.component';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  private websocketMessageSub: Subscription = new Subscription();
  private tickerSubscription: Subscription = new Subscription();
  private shouldStopTimer = true;
  public remainingTime = 0;


  constructor(
    private _data: ExerciseDataService
  ) { }

  public ngOnInit(): void {
    this.handleWebSocketMessage();
    this.startTimer();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
    this.tickerSubscription.unsubscribe();
  }

  private startTimer(): void {
    this.tickerSubscription = interval(1000).subscribe(() => {
      if (this.shouldStopTimer) {
        return;
      }

      if (this.remainingTime == 0) {
        return;
      }

      this.remainingTime--;
    });
  }

  private handleWebSocketMessage(): void {
    this.websocketMessageSub = this._data.ws.messages$.subscribe(resp => {
      if (resp.timer) {
        this.remainingTime = resp.timer;
        return;
      }

      if (resp.exercise?.environmentStatus == Status.Running) {
        this.shouldStopTimer = false;
        return;
      }

      if (resp.loading_message ||
        resp.exercise?.environmentStatus == Status.Canceled ||
        resp.exercise?.validationTestsStatus == Status.Completed ||
        resp.fatal_error
      ) {
        this.shouldStopTimer = true;
      }
    });
  }
}
