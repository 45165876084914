import { Injectable } from '@angular/core';
import { Diff, DIFF_DELETE, DIFF_EQUAL, DIFF_INSERT, patch_obj } from 'diff-match-patch';
import * as monaco from 'monaco-editor';

@Injectable({
  providedIn: 'root'
})
export class MonacoEditorHelperService {

  public applyPatch(editor: monaco.editor.IStandaloneCodeEditor, patch: patch_obj): void {
    const model: monaco.editor.ITextModel | null = editor.getModel();
    const editOperations: monaco.editor.IIdentifiedSingleEditOperation[] = [];
    const diffs: Diff[] = patch.diffs;
    let offset: number = patch.start1 ?? 0;

    if (model == null)
      return;

    diffs.forEach((diff: Diff) => {
      const [operation, text] = diff;
      let startPos: monaco.Position;
      let insertPosition: monaco.Position;
      let endPos: monaco.Position;
      let deleteRange: monaco.Range;
      let addRange: monaco.Range;

      switch (operation) {
      case DIFF_EQUAL:
        offset += text.length;
        break;

      case DIFF_DELETE:
        startPos = model.getPositionAt(offset);
        endPos = model.getPositionAt(offset + text.length);
        deleteRange = new window.monaco.Range(startPos.lineNumber, startPos.column, endPos.lineNumber, endPos.column);
        editOperations.push({
          range: deleteRange,
          text: ''
        });
        break;

      case DIFF_INSERT:
        insertPosition = model.getPositionAt(offset);
        addRange = new window.monaco.Range(insertPosition.lineNumber, insertPosition.column, insertPosition.lineNumber, insertPosition.column);
        editOperations.push({
          range: addRange,
          text: text
        });
        offset += text.length;
        break;
      }
    });

    model.pushEditOperations([], editOperations, () => null);
  }

  public changeLanguage(editor: monaco.editor.IStandaloneCodeEditor, extension: string): void {
    const languages = window.monaco.languages.getLanguages();
    const fileLanguage = languages.find(lang => lang.extensions?.includes(extension))?.id ?? 'text/plain';
    window.monaco.editor.setModelLanguage(editor.getModel()!, fileLanguage);
  }
}
