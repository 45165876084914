import { Component, OnInit } from '@angular/core';
import { ExerciseDataService } from '../../services/exercise-data.service';
import { Subscription } from 'rxjs';
import { Status } from '../../exercise.component';
import { SupportDialogComponent } from 'src/app/support/components/support-dialog/support-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit {
  private websocketMessageSub: Subscription = new Subscription();
  public message: string | null = null;
  public error: string | null = null;

  public isExerciseCompleted = false;
  public isCodeEditorOpen = false;
  public isTerminalReady = false;
  public isExerciseStarted = false;
  public isTimeout = false;
  public isCanceled = false;

  constructor(
    public _data: ExerciseDataService,
    private dialogService: DialogService,
    private messageService: MessageService,
  ) { }

  get shouldRenderLoadingMessage(): boolean {
    return !this.isExerciseStarted &&
      !this.isExerciseCompleted &&
      !this.error &&
      !this.isCanceled;
  }

  get shouldRenderTerminal(): boolean {
    return this.isExerciseStarted &&
      this.isTerminalReady &&
      !this.isCodeEditorOpen &&
      !this.error &&
      !this.isCanceled;
  }

  get shouldRenderCodeEditor(): boolean {
    return this.isExerciseStarted &&
      this.isCodeEditorOpen &&
      !this.error &&
      !this.isCanceled;
  }

  get shouldRenderNoTerminalMsg(): boolean {
    return this.isExerciseStarted &&
      !this._data.exercise?.needTerminal &&
      !this.error &&
      !this.isCanceled;
  }

  ngOnInit(): void {
    this.handleWebSocketMessage();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
  }

  public showErrorMessage(error: string): void {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
  }

  public openTicketDialog(): void {
    this.dialogService.open(SupportDialogComponent, {
      width: '550px',
      baseZIndex: 10000,
      showHeader: true,
      dismissableMask: true,
      header: 'Submit Ticket'
    });
  }

  private handleWebSocketMessage(): void {
    this.websocketMessageSub = this._data.ws.messages$.subscribe(resp => {
      if (resp.error) {
        this.showErrorMessage(resp.error);
        return;
      }

      if (resp.fatal_error) {
        this.error = resp.fatal_error;
        return;
      }

      if (resp.loading_message) {
        this.message = resp.loading_message;
        this.isExerciseStarted = false;
        this.error = null;
        return;
      }

      if (resp.exercise?.validationTestsStatus == Status.Completed) {
        this.isExerciseCompleted = true;
        return;
      }

      if (resp.exercise?.environmentStatus == Status.Running) {
        this.isExerciseStarted = true;
        this.error = null;
        return;
      }

      if (resp.terminal?.status == Status.Created) {
        this.isTerminalReady = true;
        return;
      }

      if (resp.exercise?.environmentStatus == Status.Canceled) {
        this.isCanceled = true;
        this.error = 'You have canceled the task. Press "Exit" to move on to the exercise plan';
      }

      if (resp.exercise?.environmentStatus == Status.TimeIsOver) {
        this.isTimeout = true;
        this.error = 'Exercise timed out. \n Press "Exit" to move on to the exercise plan';
      }
    });
  }
}
