<section>
    <div 
      class="loading-window"
      *ngIf="shouldRenderLoadingMessage">
      <p-progressSpinner></p-progressSpinner>
      <span>
        {{message}}
      </span>
    </div>

    <div
      class="error-window"
      *ngIf="error">
        <span>
          {{error}}
        </span>
        <exercise-button 
          *ngIf="!isTimeout && !isCanceled"
          (click)="openTicketDialog()">
          Report Error
        </exercise-button>
    </div>

    <div
     class="complete-window"
     *ngIf="isExerciseCompleted">
      <span>
        Congratulations! The validation test passed successfully! Press "Exit" to proceed to your exercise plan or hit
        "Next" to dive into the next exercise.
      </span>
    </div>

    <div
     class="terminal-not-needed-window text-yellow-700"
     *ngIf="shouldRenderNoTerminalMsg">
      <span> 
        To complete this exercise, you'll need to use your terminal. Ensure that both Git and Golang are installed on your
        computer before you begin.
      </span>
    </div>

    <app-terminal
     *ngIf="_data.exercise?.needTerminal"
     [style.display]="shouldRenderTerminal  ? 'flex': 'none'"
     [isResizable]="!isCodeEditorOpen">
    </app-terminal>

    <app-code-editor 
      *ngIf="_data.exercise?.needCodeEditor"
      [style.display]="shouldRenderCodeEditor ? 'flex': 'none'"
      (isOpenChange)="isCodeEditorOpen = $event">
   </app-code-editor>
</section>

<plan-completion></plan-completion>