import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { RegistrationComponent } from './registration/registration.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PolicyComponent } from './policy/policy.component';
import { CookiesComponent } from './cookies/cookies.component';
import { TermsComponent } from './terms/terms.component';
import { ReplayComponent } from './replay/components/replay.component';
import { PaymentComponent } from './payment/payment.component';
import { ContactComponent } from './contact/contact.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PricesComponent } from './prices/prices.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { ExerciseManagementComponent } from './exercise-management/exercise-management.component';
import { PlansManagementComponent } from './plans-management/plans-management.component';
import { AssignedPlansComponent } from './assigned-plans/assigned-plans.component';
import { UserExercisePlansComponent } from './user-exercise-plans/user-exercise-plans.component';
import { InterviewerGuard } from './guards/interviewer-guard/interviewer.guard';
import { TicketListComponent } from './support/components/ticket-list/ticket-list.component';
import { AdminTicketListComponent } from './admin-panel/components/admin-ticket-list/admin-ticket-list.component';
import { SupportGuard } from './guards/support-guard/support.guard';
import { ProfileComponent } from './settings/components/profile/profile.component';
import { CompanyProfileComponent } from './settings/components/company-profile/company-profile.component';
import { UserConnectionsComponent } from './settings/components/user-connections/user-connections.component';
import { ExerciseAccessGuard } from './guards/exercise-access/exercise-access.guard';
import { UserGuard } from './guards/user-guard/user.guard';
import { DocumentationComponent } from './documentation/components/documentation/documentation.component';


const routes: Routes = [
  { path: 'cookies', component: CookiesComponent },
  { path: 'exercise/:urlId/:exerciseId/:planId', component: ExerciseComponent, canActivate: [AuthGuard, ExerciseAccessGuard] },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: 'company', component: CompanyProfileComponent },
      { path: 'connections', component: UserConnectionsComponent }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'payments', component: PaymentComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'prices', component: PricesComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'replay/:companyId/:assignedPlanId/:exerciseId/:base64Email', component: ReplayComponent, canActivate: [AuthGuard, InterviewerGuard] },
  { path: 'contact', component: ContactComponent, canActivate: [AuthGuard] },
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: 'payment/history', component: PaymentHistoryComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'plans-management', component: PlansManagementComponent, canActivate: [AuthGuard, InterviewerGuard] },
  { path: 'exercises', component: ExerciseManagementComponent, canActivate: [AuthGuard] },
  { path: 'assigned-plans', component: AssignedPlansComponent, canActivate: [AuthGuard, InterviewerGuard] },
  { path: 'plans', component: UserExercisePlansComponent, canActivate: [AuthGuard, UserGuard] },
  { path: 'policy', component: PolicyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'documentation', component: DocumentationComponent}, 
  { path: 'tickets', component: TicketListComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminTicketListComponent, canActivate: [AuthGuard, SupportGuard] },
  { path: '', component: LandingPageComponent },
  { path: '**', component: LandingPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
