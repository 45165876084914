import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { ExerciseDataService } from 'src/app/exercise/services/exercise-data.service';
import { ExercisePlansDataService } from 'src/app/user-exercise-plans/exercise-plans-data-service/exercise-plans-data.service';

@Component({
  providers: [ConfirmationService],
  selector: 'plan-completion',
  templateUrl: './plan-completion.component.html',
  styleUrls: ['./plan-completion.component.scss']
})
export class PlanCompletionComponent implements OnInit, OnDestroy {
  private nextExerciseSub: Subscription | null = null;
  private assignedPlanId = '';
  private isWelcomePlan = false;
  public readonly dialogKey: string = 'complete-plan-dialog';

  constructor(
    public _data: ExerciseDataService,
    private _planData: ExercisePlansDataService,
    private _dialog: ConfirmationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private _dynamodb: DynamoDBService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.assignedPlanId = this._route.snapshot.paramMap.get('planId') ?? '';
    this.isWelcomePlan = await this._dynamodb.isWelcomePlan(this._auth.user.email, this.assignedPlanId);
    this.checkNextExercise();
  }

  public ngOnDestroy(): void {
    this.nextExerciseSub?.unsubscribe();
  }

  private checkNextExercise(): void {
    this.nextExerciseSub = this._data.nextExercise$.subscribe(exercise => {
      if (exercise === undefined)
        return;

      if (this.isWelcomePlan && !exercise) {
        this.markPlanAsFinished();
        this.openDialog();
        return;
      }

      if (!exercise) {
        this.markPlanAsFinished();
      }
    });
  }

  private markPlanAsFinished(): void {
    this._planData.data.forEach(plan => {
      if (plan.id === this.assignedPlanId)
        plan.isFinished = true;
    });
  }

  private openDialog(): void {
    this._dialog.confirm({
      dismissableMask: true,
      key: this.dialogKey,
      header: 'Congratulations!',
      message: `Thanks for exploring our project and finishing the Welcome Plan! 
                Hope you enjoyed it. We would be grateful if you click the "Support Us" 
                button to help cover our costs and continue improving!`,
      accept: () => {
        this._router.navigate(
          ['/payment'],
          { queryParams: { email: this._auth.user.email, assigned_plan_id: this.assignedPlanId } }
        );
      },
      reject: (type: number) => {
        if (type === 1)
          this.returnToPlan();
      }
    });
  }

  private returnToPlan(): void {
    this._router.navigate(['/plans'], { queryParams: { id: this.assignedPlanId } });
  }
}
