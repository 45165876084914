import { Component, OnInit } from '@angular/core';
import { Command, Message, Status } from '../../../../exercise.component';
import { Subscription } from 'rxjs';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { User } from 'src/app/user.module';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit {
  private websocketMessageSub: Subscription = new Subscription();
  private nextExerciseSub: Subscription | null = null;
  private assignedPlanId = '';
  public readonly dialogKey: string = 'cancel-exercise-dialog';
  public nextExerciseURL: string | null = null;
  public isExerciseStarted = true;
  public isExerciseCompleted = false;
  public hasError = false;
  public user: User | null = null;

  constructor(
    public _data: ExerciseDataService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _dialog: ConfirmationService,
    private _authService: AuthService
  ) { }

  get isSubmitBtnBlocked(): boolean {
    return !this.isExerciseStarted ||
      this.hasError;
  }

  public ngOnInit(): void {
    this.assignedPlanId = this._route.snapshot.paramMap.get('planId') ?? '';
    this.user = this._authService.user;
    this.handleWebsocketMessage();
    this.fetchNextExercise();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub?.unsubscribe();
    this.nextExerciseSub?.unsubscribe();
  }

  public submit(): void {
    const message: Message = { command: Command.Validate };
    this._data.ws.sendMessage(JSON.stringify(message));
  }

  public restart(): void {
    const message: Message = { command: Command.Restart };
    this._data.ws.sendMessage(JSON.stringify(message));
  }

  public cancel(): void {
    this._dialog.confirm({
      dismissableMask: true,
      header: 'Exercise Cancellation',
      message: `Are you sure you want to cancel this exercise? Please note that once cancelled, 
                you will not be able to access this exercise anymore.`,
      key: this.dialogKey,
      accept: () => {
        const message: Message = { command: Command.Cancel };
        this._data.ws.sendMessage(JSON.stringify(message));
      },
    });
  }

  public returnToPlanList(): void {
    this._router.navigate(['/plans'], { queryParams: { id: this.assignedPlanId } });
  }

  public startReferenceSolution(): void {
    const message: Message = { command: Command.ReferenceSolution };
    this._data.ws.sendMessage(JSON.stringify(message));
  }

  private handleWebsocketMessage(): void {
    this.websocketMessageSub = this._data.ws.messages$.subscribe(async resp => {
      if (resp.loading_message) {
        this.isExerciseStarted = false;
        return;
      }

      if (resp.fatal_error) {
        this.isExerciseStarted = true;
        this.hasError = true;
        return;
      }

      if (resp.exercise?.environmentStatus == Status.Running) {
        this.isExerciseStarted = true;
      }

      if (resp.exercise?.validationTestsStatus == Status.Completed) {
        this.isExerciseCompleted = true;
      }

      if (
        resp.exercise?.environmentStatus == Status.TimeIsOver ||
        resp.exercise?.environmentStatus == Status.Canceled
      ) {
        this.hasError = true;
        this.isExerciseStarted = false;
      }
    });
  }

  private fetchNextExercise(): void {
    this.nextExerciseSub = this._data.nextExercise$.pipe(
      filter(exercise => exercise !== null)
    ).subscribe(exercise => {
      this.nextExerciseURL = exercise ? `/exercise/${exercise.urlID}/${exercise.exerciseID}/${this.assignedPlanId}` : null;
    });
  }

  public async goToNextExercise(): Promise<void> {
    const route = this.nextExerciseURL ? [this.nextExerciseURL] : ['/plans'];
    await this._router.navigate(route);
    window.location.reload();
  }
}
